import { createSlice } from '@reduxjs/toolkit';
import {
  getUpcomingTripsList,
  getTrip,
  getLabels
} from './printLabelsFc.actions';
import { tryTranslateError } from '@/helpers/helpers';

const initialState = {
  status: '',
  labelsStatus: '',
  data: {},
  selectedTrip: {},
  labels: null
};

const printLabelsFcSlice = createSlice({
  name: 'printLabelsFc',
  initialState,
  reducers: {
    resetLabelsFc: (state) => {
      state.data = {};
      state.status = '';
      state.selectedTrip = {};
      state.labels = null;
      state.labelsStatus = '';
      state.error = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getLabels.fulfilled, (state, { payload }) => {
        state.labels = payload;
        state.labelsStatus = 'success';
      })
      .addCase(getLabels.rejected, (state, { payload }) => {
        state.labels = null;
        state.labelsStatus = 'failed';
        state.error = tryTranslateError(payload);
      })
      .addCase(getUpcomingTripsList.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = 'success';
      })
      .addCase(getUpcomingTripsList.rejected, (state, action) => {
        state.data = {};
        state.status = 'failed';
      })
      .addCase(getTrip.pending, (state) => {
        state.selectedTrip = {};
        state.status = 'loading';
      })
      .addCase(getTrip.fulfilled, (state, { payload }) => {
        state.selectedTrip = payload;
      })
      .addCase(getTrip.rejected, (state, action) => {
        state.data = {};
      });
  }
});

export const { resetLabelsFc } = printLabelsFcSlice.actions;

export default printLabelsFcSlice.reducer;
