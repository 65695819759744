import { createSlice } from '@reduxjs/toolkit';
import { validateScan } from '@/redux/barcode/barcode.actions';

const initialState = {
  value: '',
  isScanValid: undefined,
  warning: false,
  type: undefined,
  message: undefined,
  secondaryMessage: undefined,
  returnedBoxErrors: [],
  ReturnBoxCoolerErrorMessage: '',
  returnedBoxScans: [],
  availableBoxes: null,
  unavailableBoxes: null,
  availableCoolers: null,
  unavailableCoolers: null
};

const barcodeSlice = createSlice({
  name: 'barcode',
  initialState,
  reducers: {
    resetIsScanValid: (state) => {
      state.value = '';
      state.isScanValid = undefined;
      state.message = undefined;
      state.secondaryMessage = undefined;
      state.availableBoxes = null;
      state.unavailableBoxes = null;
      state.availableCoolers = null;
      state.unavailableCoolers = null;
    },
    resetReturnedBoxErrors: (state) => {
      state.returnedBoxErrors = [];
      state.ReturnBoxCoolerErrorMessage = '';
    },
    resetWarning: (state) => {
      state.warning = false;
    },
    updateBoxCount: (state, payload) => {
      state.availableBoxes = payload.payload.availableBoxes;
      state.unavailableBoxes = payload.payload.unavailableBoxes;
    },
    updateCoolerCount: (state, payload) => {
      state.availableCoolers = payload.payload.availableCoolers;
      state.unavailableCoolers = payload.payload.unavailableCoolers;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(validateScan.fulfilled, (state, { payload }) => {
        state.value = payload?.value;
        state.isScanValid = payload?.isScanValid;
        state.warning = payload?.warning;
        state.type = payload?.type;
        state.message = payload?.message;
        state.secondaryMessage = payload?.secondaryMessage;

        if (payload?.type === 'return_box_cooler' && payload?.isScanValid) {
          state.returnedBoxScans.push(payload.value);

          if (state.returnedBoxScans.length > 5) {
            state.returnedBoxScans = state.returnedBoxScans.slice(-5);
          }
        }

        state.returnedBoxErrors = state.returnedBoxErrors.filter(
          (box) => box !== payload?.value
        );

        if (payload?.missingProducts) {
          state.missingProducts = payload.missingProducts;
        }
      })
      .addCase(validateScan.rejected, (state, payload) => {
        state.isScanValid = payload?.payload?.isScanValid;
        state.warning = payload?.payload?.warning;

        if (payload?.payload?.errorText !== undefined)
          state.ReturnBoxCoolerErrorMessage = payload?.payload.errorText;

        if (!state.returnedBoxErrors.includes(payload?.payload?.value)) {
          state.returnedBoxErrors.push(payload?.payload?.value);
        }
      });
  }
});

export const {
  resetIsScanValid,
  resetWarning,
  updateBoxCount,
  resetReturnedBoxErrors,
  setMissingProducts,
  updateCoolerCount
} = barcodeSlice.actions;

export default barcodeSlice.reducer;
