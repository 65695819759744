import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import {
  SignInPage,
  SelectMode,
  SelectRound,
  ScanRack,
  SupplyScanProduct,
  FillScanProduct,
  AdminPage,
  ActivateTripPage,
  ResetRack,
  PrintLabel,
  SettingsPage,
  EnvironmentPage,
  Fillv2ScanProduct,
  ShipBox
} from '@/views';
import { WithHeaderLayout, Layout } from '@/components';
import { UserProvider } from './contexts/UserContext';
import store from '@/redux/store';
import ScanBasket from './views/FillScanProduct/ScanBasket/ScanBasket';
import PrintLabelFaq from './views/Print/PrintLabelFaq/PrintLabelFaq';
import PrintLabelFaqElement from './views/Print/PrintLabelFaqElement/PrintLabelFaqElement';
import WarehouseFlows from './views/WarehouseFlows/WarehouseFlows/WarehouseFlows';
import Restock from './views/Restock/Restock/Restock';
import BatchDetails from './views/Restock/BatchDetails/BatchDetails';
import ReturnBoxCooler from './views/ReturnBoxCooler/ReturnBoxCooler';
import Picking from './views/Picking/Picking';
import FillScanBasket from './views/Fillv2/FillScanBasket/FillScanBasket';
import { isProd } from './helpers/api';
import PrintLabelsFc from './views/PrintLabelsFc/PrintLabelsFc';
import PrintLabelsFcTrip from './views/PrintLabelsFc/PrintLabelsFcTrip';
import MissingProducts from './views/MissingProducts/MissingProducts';
import ErrorBoundary from './ErrorBoundary';
import InspectMob from './views/InspectMob/InspectMob';
import MobInfos from './views/InspectMob/MobInfos/MobInfos';
import Miniboard from './views/Admin/Miniboard/Miniboard';

const App = () => (
  <ErrorBoundary>
    <Router>
      <ReduxProvider store={store}>
        <UserProvider>
          <Layout>
            <Routes>
              <Route path="/signin" element={<SignInPage />} />
              <Route element={<WithHeaderLayout />}>
                <Route exact path="/" element={<SelectMode />} />
                {/* Supply mode routes */}
                <Route exact path="/supply" element={<SelectRound />} />
                <Route exact path="/supply/:round" element={<ScanRack />} />
                <Route
                  exact
                  path="/supply/:round/:rack"
                  element={<SupplyScanProduct />}
                />

                {/* PickingV2 mode routes */}
                <Route exact path="/pickingv2" element={<SelectRound />} />
                <Route exact path="/pickingv2/:round" element={<Picking />} />
                <Route
                  exact
                  path="/pickingv2/:round/:rack"
                  element={<SupplyScanProduct />}
                />

                {/* Fill baskets mode routes */}
                <Route exact path="/fill" element={<ScanBasket />} />
                <Route
                  exact
                  path="/fill/:packageNumber"
                  element={<FillScanProduct />}
                />

                {/* Print label routes */}
                <Route exact path="/print" element={<PrintLabel />} />
                <Route exact path="/print/faq" element={<PrintLabelFaq />} />
                <Route
                  exact
                  path="/print/faq/:item"
                  element={<PrintLabelFaqElement />}
                />

                {/* Restock mode routes */}
                <Route exact path="/restock" element={<Restock />} />
                <Route
                  exact
                  path="/restock/:batchId"
                  element={<BatchDetails />}
                />

                {/* Warehouse flows mode routes */}
                <Route
                  exact
                  path="/warehouseflows"
                  element={<WarehouseFlows />}
                />

                {/* Return box mode routes */}
                <Route exact path="/boxreturn" element={<ReturnBoxCooler />} />

                {/* Fill v2 mode routes */}
                <Route exact path="/fillv2" element={<FillScanBasket />} />
                <Route
                  exact
                  path="/fillv2/:packageNumber"
                  element={<Fillv2ScanProduct />}
                />

                {/* Missing products mode routes */}
                <Route
                  exact
                  path="/missingproducts"
                  element={<MissingProducts />}
                />

                {/* Print labels FC routes */}
                <Route
                  exact
                  path="/printlabelsfc"
                  element={<PrintLabelsFc />}
                />
                <Route
                  exact
                  path="/printlabelsfc/:tripId"
                  element={<PrintLabelsFcTrip />}
                />

                {/* Ship box mode routes */}
                <Route exact path="/shipbox" element={<ShipBox />} />

                {/* Inspect Mob mode routes */}
                <Route exact path="/inspectmob" element={<InspectMob />} />
                <Route exact path="/inspectmob/:mobId" element={<MobInfos />} />

                {/* Admin routes */}
                <Route exact path="/admin" element={<AdminPage />} />
                <Route exact path="/settings" element={<SettingsPage />} />
                <Route
                  exact
                  path="/settings/environment"
                  element={<EnvironmentPage />}
                />
                <Route
                  exact
                  path="/admin/active-trip"
                  element={<ActivateTripPage />}
                />
                <Route exact path="/admin/reset-mob" element={<ResetRack />} />
                <Route exact path="/admin/miniboard" element={<Miniboard />} />
              </Route>
            </Routes>
            {!isProd && (
              <div
                style={{
                  position: 'fixed',
                  bottom: '10px',
                  right: '10px',
                  padding: '10px',
                  backgroundColor: 'rgb(252, 112, 51)',
                  opacity: '0.9',
                  fontSize: '18px',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  fontWeight: '600'
                }}
              >
                ⚠️ TEST MODE
              </div>
            )}
          </Layout>
        </UserProvider>
      </ReduxProvider>
    </Router>
  </ErrorBoundary>
);

export default App;
