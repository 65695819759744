/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchPickingProducts, fetchTripInfos } from './picking.actions';

const initialState = {
  pickingStep: 'starting',
  corValue: '',
  productIndex: 0,
  error: null,
  data: undefined,
  productToScan: {},
  tripInfos: null,
  previousScanned: null,
  canScan: true
};

const pickingSlice = createSlice({
  name: 'picking',
  initialState,
  reducers: {
    updatePickingStep: (state, action) => {
      state.pickingStep = action.payload.pickingStep;
    },

    updateTripInfos: (state, action) => {
      state.pickingStep = action.payload.pickingStep;
    },

    updateCorValue: (state, action) => {
      state.corValue = action.payload.corValue;
    },
    updateProductIndex: (state, data) => {
      const firstUnprocessedIndex = data.payload.data.findIndex(
        (product) => product.pickingStatus === 'PENDING_SCAN'
      );
      if (data.payload.data.length !== 0 && firstUnprocessedIndex === -1) {
        state.productIndex = data.payload.data.length - 1;
      }
      if (firstUnprocessedIndex !== -1) {
        state.productIndex = firstUnprocessedIndex;
      }
      state.productToScan = data.payload.data[firstUnprocessedIndex];
      state.previousScanned = data.payload.data[firstUnprocessedIndex - 1];
    },
    cancelPreviousProductScan: (state, { payload }) => {
      const warning = payload;
      state.productToScan = state.previousScanned;
      const previousScannedIndex = state.data.findIndex(
        (p) => p.shopify_id === state.previousScanned.shopify_id
      );

      if (warning) {
        // Cancel scan d'un produit manquant
        state.missingProducts -= state.previousScanned.qty;
        state.data.unshift(state.previousScanned);
      } else {
        state.totalScanned -= 1;

        if (previousScannedIndex !== -1) {
          state.data[previousScannedIndex].qty += 1;
        } else {
          state.data.unshift({ ...state.previousScanned, qty: 1 });
        }
      }
      state.previousScanned = {};
    },

    processValidProductScan: (state, { payload }) => {},
    resetPickingData: (state) => {
      state.productToScan = {};
      state.previousScanned = null;
      state.pickingStep = 'starting';
      state.corValue = '';
      state.data = undefined;
      state.tripInfos = null;
      state.canScan = true;
      state.error = null;
    },
    setMobileRack: (state, { payload }) => {
      console.log('setmobilerack payload', payload);
      state.mobileRack = payload;
    },
    setCanScan: (state, { payload }) => {
      state.canScan = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPickingProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPickingProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.error = null;
        console.log('fetchsupply products fullfilled payload');
        console.table(payload);
        state.data = payload;
      })
      .addCase(fetchPickingProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchTripInfos.fulfilled, (state, { payload }) => {
        state.tripInfos = payload;
      });
  }
});

function selectProducts(state) {
  return state.picking.data;
}

export const selectSupplyTotalProducts = createSelector(
  selectProducts,
  (products) => products.reduce((prev, curr) => prev + curr.qty, 0)
);

export const {
  updatePickingStep,
  getSupplyProducts,
  processValidProductScan,
  cancelPreviousProductScan,
  resetSupplyProductsData,
  setMobileRack,
  resetPickingData,
  updateCorValue,
  updateProductIndex,
  setCanScan
} = pickingSlice.actions;

export default pickingSlice.reducer;
